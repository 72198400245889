import React, { Component, createRef, useCallback } from "react";
import SimpleMap from "../../../components/GoogleMapComponent/index";
import Footer from "../../../components/UI/Footer";
import CsvDownloadButton from "react-json-to-csv";
import * as XLSX from "xlsx";
import "./style.css";
import {
  Grid,
  Typography,
  Container,
  Paper,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Card,
  CardHeader,
  CardMedia,
  CardActionArea,
  Button,
  
} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import AlertBox from "./AlertBox.js";
import ErrorFallbackComponent from "../../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import { InputGroup } from "react-bootstrap";
import { FormControl as FC } from "react-bootstrap";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { apiPaths } from "../../../services/apiPath";
import AdminNavBar from "../../../components/UI/AdminNavBar";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../../store";
import config from "../../../services/apiConfig";
import EventDetail from "../../../components/CreateEventComponent/EventModal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { AWS_URL } from "../../../assets/Constants";
import ModalDate from "./ModalDate.js";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TextField from "@mui/material/TextField";
import Axios from "axios";

const states = ["ACT", "NSW", "VIC", "SA", "TAS", "NT", "WA", "QLD"];
class ViewPendingVendors extends Component {
  emailRef = createRef();
  fullNameRef = createRef();
  state = {
    accessType: "",
    accessEmail: "",
    accessOptions: ["Electrician", "Health Department", "City Council"],
    
    isDialogVisible: false,
    isDetailsVisible: false,
    selectedVendorAlert: {},
    isSuperAdminLoggedIn: "false",
    isByState: "false",
    selectedState: "",
    selectedVendorIds: [],
    loading: false,
    isLoading: false,
    selectedVendorPoolIds: [],
    showModal: false,
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    organiserData: !getFromLocalStorage("Organiser")
      ? {}
      : JSON.parse(getFromLocalStorage("Organiser"))[0],
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    canUseCallout:
      getFromLocalStorage("canUseCallout") != "undefined" &&
      getFromLocalStorage("canUseCallout") != "" &&
      getFromLocalStorage("canUseCallout") != null
        ? getFromLocalStorage("canUseCallout")
        : "false",
    vendorData: [],
    statePool: [
      {
        title: "Victoria",
        id: 1,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Flag_of_Victoria_%28Australia%29.svg/2880px-Flag_of_Victoria_%28Australia%29.svg.png",
      },
      {
        title: "New South Wales",
        id: 2,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_New_South_Wales.svg/2880px-Flag_of_New_South_Wales.svg.png",
      },
      {
        title: "Queensland",
        id: 6,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Flag_of_Queensland.svg/1280px-Flag_of_Queensland.svg.png",
      },
      {
        title: "South Australia",
        id: 7,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Flag_of_South_Australia.svg/1200px-Flag_of_South_Australia.svg.png",
      },
      {
        title: "Northern Territory",
        id: 5,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_the_Northern_Territory.svg",
      },
      {
        title: "Australian Capital Territory",
        id: 4,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Flag_of_the_Australian_Capital_Territory.svg/250px-Flag_of_the_Australian_Capital_Territory.svg.png",
      },
      {
        title: "Western Australia",
        id: 8,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_Western_Australia.svg",
      },
      {
        title: "Tasmania",
        id: 3,
        logo:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Flag_of_Tasmania.svg/1200px-Flag_of_Tasmania.svg.png",
      },
    ],
    acceptedVendorData: [],
    vendorPool: [],
    tempVendorPool: [],
    rejectedVendorData: [],
    eventList: [],
    isOpen: false,
    selectedVendor: {},
    confirmVendors: [],
    noticeOpen: false,
    contractNoticeOpen: false,
    missingDoc: [],
    selectEvent: {},
    defaultLink: "../../../assets/img/welcome-logo.png",
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };
  toggleDialog = () => {
    this.setState((prevState) => ({
      isDialogVisible: !prevState.isDialogVisible,
    }));
  };

  toggleDetails = () => {
    this.setState({ isDetailsVisible: !this.state.isDetailsVisible });
  };

  acceptVendors = async () => {
    const url = config.devUrl + apiPaths.adminData;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "accepted_vendor_outlets",
          event_id: this.state.selectEvent.id,
          accepted_vendor_ids: this.state.selectedVendorIds,
        },
      },
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      alert("Applying please wait!");

      if (result.success === true) {
        alert("Apply succeeded!");
        this.setState({ selectedVendorIds: [] });
        this.getTargetedEvents(this.state.selectEvent.id);
        this.getTargetedAcceptedEvents(this.state.selectEvent.id);
      } else {
        alert("The application failed. Please try later");
        this.getTargetedEvents(this.state.selectEvent.id);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      alert("The application failed. Please try later");
      this.setState({ selectedVendorIds: [] });
    }
  };

  alertMultipleCheckbox = async () => {
    const { selectedVendorPoolIds } = this.state;
    console.log(selectedVendorPoolIds);
    console.log(this.state.selectEvent.eoi_event_name);
    if (this.state.selectEvent && this.state.selectEvent.eoi_event_name) {
      const url = config.BASE_URI + apiPaths.organiserData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "alert_vendor_for_eoi",
            eoi_id: this.state.selectEvent.id,
            vendor_id: selectedVendorPoolIds,
            state_id: 0,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.success === true) {
            alert(
              `Alerted the selected vendors for ${this.state.selectEvent.eoi_event_name}`
            );
          } else {
            alert("Please try again!");
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          alert("Please Try again!");
        });
    }
  };

  handleSendAlert = () => {
    const { selectedVendorAlert } = this.state;
    console.log(selectedVendorAlert);
    console.log(this.state.selectEvent.eoi_event_name);
    if (this.state.selectEvent && this.state.selectEvent.eoi_event_name) {
      const url = config.BASE_URI + apiPaths.organiserData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "alert_vendor_for_eoi",
            eoi_id: this.state.selectEvent.id,
            vendor_id: selectedVendorAlert.user_ptr_id,
            state_id: 0,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.success === true) {
            alert(
              `Alerted ${selectedVendorAlert.title} for ${this.state.selectEvent.eoi_event_name}`
            );
          } else {
            alert(dataResponse.message);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          alert("Please Try again!");
        });
    }
    this.toggleDialog(); // Close the dialog box after API call
  };
  saveDatesAndSendData = (selectedDates) => {
    console.log(selectedDates);
    this.toggleModal();

    const url = config.devUrl + apiPaths.adminData;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let vendorId = this.state.confirmVendors.map((item) => item.id);

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "accepted_vendor_outlets",
          event_id: this.state.selectEvent.id,
          accepted_vendor_ids: vendorId,
          selected_dates: selectedDates, // Include selected dates in the payload
        },
      },
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            alert("Apply successed!");
            this.setState({
              confirmVendors: [],
            });
            this.getTargetedEvents(this.state.selectEvent.id);
            this.getTargetedAcceptedEvents(this.state.selectEvent.id);
          } else {
            alert("The application is failed. Please try later");
            this.setState({
              confirmVendors: [],
            });
            this.getTargetedEvents(this.state.selectEvent.id);
          }
        });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      alert("The application is failed. Please try later");
      this.setState({ confirmVendors: [] });
    }
    vendorId.length = 0;
  };

  getVendorPool = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_eoi",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    const res = await response.json();
    if (res.success == true) {
      console.log(res);
      this.setState({
        vendorPool: res.vendors,
        tempVendorPool: res.vendors,
      });
    }
  };

  getAllVendors = async (vendorList) => {
    if (vendorList.length == 0) {
      this.setState({ vendorData: vendorList });
      return;
    }
    let applyVendor = [];

    await Promise.allSettled(
      vendorList.map(async (item) => {
        const vendorUrl =
          config.BASE_URI + apiPaths.explore.trucksList + item.vendor_outlets;

        const response = await fetch(vendorUrl, {
          method: "GET",
          redirect: "follow",
        });
        const responseData = await response.json();

        let isIdPresent = false;
        if (applyVendor) {
          for (let i = 0; i < applyVendor.length; i++) {
            if (applyVendor[i].id === responseData.id) {
              isIdPresent = true;
              break;
            }
          }
          if (!isIdPresent) {
            applyVendor.push(responseData);
          }
        }
        // alert("vendor " + applyVendor.title);
        this.setState({ vendorData: applyVendor });

        // .then((response) =>
        //   response
        //     .json()
        //     .then((responseData) => {
        //       console.log(responseData);

        //     })
        //     .then((response) => {
        //       console.log(applyVendor);

        //     })
        // );
      })
    );
  };

  handleChange = (event) => {
    const selectedState = event.target.value;
    let selectedNumber = 0;
    switch (selectedState) {
      case "NSW":
        selectedNumber = 2;
        break;
      case "VIC":
        selectedNumber = 1;
        break;
      case "QLD":
        selectedNumber = 6;
        break;
      case "WA":
        selectedNumber = 8;
        break;
      case "ACT":
        selectedNumber = 4;
        break;
      case "NT":
        selectedNumber = 5;
        break;
      case "SA":
        selectedNumber = 7;
        break;
      case "TAS":
        selectedNumber = 3;
        break;
    }
    let tempVendorPool = this.state.vendorPool;
    if (selectedState) {
      tempVendorPool = this.state.vendorPool.filter((item) => {
        return item.geo_location === selectedNumber;
      });
    }
    this.setState({ selectedState, tempVendorPool });
  };

  getAcceptedVendors = async (vendorList) => {
    if (vendorList.length == 0) {
      this.setState({ acceptedVendorData: vendorList });
      return;
    }
    let applyVendor = [];

    vendorList.map((item) => {
      const vendorUrl =
        config.BASE_URI + apiPaths.explore.trucksList + item.accepted_outlets;

      fetch(vendorUrl, {
        method: "GET",
        redirect: "follow",
      }).then((response) =>
        response
          .json()
          .then((responseData) => {
            console.log(responseData);
            let isIdPresent = false;
            if (applyVendor) {
              for (let i = 0; i < applyVendor.length; i++) {
                if (applyVendor[i].id === responseData.id) {
                  isIdPresent = true;
                  break;
                }
              }
              if (!isIdPresent) {
                applyVendor.push(responseData);
              }
            }
          })
          .then((response) => {
            console.log(applyVendor);
            this.setState({ acceptedVendorData: applyVendor });
          })
      );
    });
  };

  getRejected = async (vendorList) => {
    if (vendorList.length == 0) {
      this.setState({ rejectedVendorData: vendorList });
      return;
    }
    let applyVendor = [];

    vendorList.map((item) => {
      const vendorUrl =
        config.BASE_URI + apiPaths.explore.trucksList + item.rejected_outlets;

      fetch(vendorUrl, {
        method: "GET",
        redirect: "follow",
      }).then((response) =>
        response
          .json()
          .then((responseData) => {
            console.log(responseData);
            let isIdPresent = false;
            if (applyVendor) {
              for (let i = 0; i < applyVendor.length; i++) {
                if (applyVendor[i].id === responseData.id) {
                  isIdPresent = true;
                  break;
                }
              }
              if (!isIdPresent) {
                applyVendor.push(responseData);
              }
            }
          })
          .then((response) => {
            console.log(applyVendor);
            this.setState({ rejectedVendorData: applyVendor });
          })
      );
    });
  };

  getTargetedEvents = async (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_applied_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(url, requestOptions);
    const res = await response.json();

    const vendorList = res.event_application;
    console.log(vendorList);
    await Promise.all([this.getAllVendors(vendorList), this.getVendorPool()]);

    // .catch((error) => {
    //   Sentry.captureException(error);
    //   console.error(error);
    // });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getTargetedAcceptedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_accepted_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const acceptedVendorList = response.accepted_applications;
        this.getAcceptedVendors(acceptedVendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getTargetedRejectedEvents = (id) => {
    this.setState({ isLoading: true });
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_rejected_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ isLoading: false });
        const acceptedVendorList = response.rejected_applications;
        this.getRejected(acceptedVendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    this.setState({
      selectEvent: this.state.eventList?.filter((item) => item.id == id)[0],
    });
  };

  getEvents = async () => {
    let newEvent = [];
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    const emailID = getFromLocalStorage("email");
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_current_eoi",
          email_id: emailID,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ eventList: data.eoi });
      });
  };
  componentDidMount = () => {
    this.getEvents();
  };

  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/events");
  };
  onClose = () => {
    this.setState({ isOpen: false });
  };

  onComfirm = (vendor) => {
    const documents = [
      { doc: vendor.food_refistration_docs, name: "food Registration Docs" },
      { doc: vendor.liablity_certificate, name: "Liablity Certificate" },
      { doc: vendor.meals, name: "Meals" },
      { doc: vendor.electricial_certificate, name: "Electrical Certificeate" },
      { doc: vendor.gas_certificate, name: "Gas certificate" },
      { doc: vendor.site_layout, name: "Site LayOut" },
    ];
    const missingDocument = [];
    documents.map((item) => {
      if (!item.doc) {
        missingDocument.push(item);
      }
    });
    if (missingDocument.length != 0) {
      this.setState({ missingDoc: missingDocument });
      this.setState({ noticeOpen: true });
    } else {
      if (this.state.confirmVendors.includes(vendor)) {
        alert("this Vendor aleady added!");
        this.onClose();
      } else {
        this.setState({
          confirmVendors: [...this.state.confirmVendors, vendor],
        });
        this.onClose();
      }
    }
  };
  onReject = (vendor) => {
    // console.log(vendor + "Vendor Yasss");
    this.setState((pre, props) => {
      return {
        vendorData: pre.vendorData.filter((item) => item.id !== vendor.id),
      };
    });

    const url = config.devUrl + apiPaths.adminData;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const vendorId = [];

    this.state.confirmVendors.map((item) => {
      vendorId.push(item.id);
    });
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "rejected_vendor_outlets",
          event_id: this.state.selectEvent.id,
          rejected_vendor_ids: vendor.id,
          operator_id: 48,
        },
      },
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          alert("You have rejected the selected vendor");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong");
      });
  };

  removeVendor = (vendor) => {
    this.setState({
      confirmVendors: this.state.confirmVendors.filter(
        (item) => item !== vendor
      ),
    });
  };
  openNotice = () => {
    this.setState({ noticeOpen: true });
  };

  ContractNoticeComponent = ({ openContractNotice, selectEvent }) => {
    console.log(selectEvent);
    return (
      <Dialog
        open={openContractNotice}
        onClose={() => this.setState({ contractNoticeOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Contract"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to use your own contract or do you want to use our
            tempalete to generate final contract?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ contractNoticeOpen: false });
            }}
          >
            Use Your contract
          </Button>

          <Button
            onClick={() => {
              this.setState({ contractNoticeOpen: false });
              window.open("/contract-generator");
            }}
          >
            Use Our Contract Template
          </Button>

          <Button onClick={() => this.setState({ contractNoticeOpen: false })}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  NoticeComponent = ({ document, selectedVendor, noticeOpens }) => {
    return (
      <Dialog
        open={noticeOpens}
        onClose={() => this.setState({ noticeOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Missing documentation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Documents is not uploaded by vendor owners, do you still want to
            accept this vendor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ noticeOpen: false })}>
            Disagree
          </Button>
          <Button
            onClick={() => {
              if (this.state.confirmVendors.includes(selectedVendor)) {
                alert("this Vendor aleady added!");
                this.setState({ noticeOpen: false });
              } else {
                this.setState({
                  confirmVendors: [
                    ...this.state.confirmVendors,
                    selectedVendor,
                  ],
                });
              }
              this.setState({ noticeOpen: false });
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleSubmitShareAccess = async () => {
    const confirmationMessage = `Are you sure you want to send this to ${this.state.accessType}?`;
    const isConfirmed = window.confirm(confirmationMessage);

    if (!isConfirmed) {
      return; // Abort the operation if not confirmed
    }

    try {
      const response = await Axios.post(
        config.BASE_URI + apiPaths.organiserData,
        {
          payload: {
            body: {
              query_type: "share_departmental_access",
              type_of_access: this.state.accessType,
              email: this.emailRef.current.value,
              fullName: this.fullNameRef.current.value,
              organiserID: this.state.organiserData.id ?? "ID",
              eoiID: this.state.selectEvent.id,
            },
          },
        }
      );
      console.log('Response:', response);
      // Handle success response
    } catch (error) {
      console.error('Error:', error);
      // Handle error response
    }
  }
  render() {
    const {
      isDialogVisible,
      isDetailsVisible,
      isSuperAdminLoggedIn,
      isByState,
      isOrganiserLoggedIn,
      vendorName,
      vendorData,
      acceptedVendorData,
      rejectedVendorData,
      vendorPool,
      statePool,
      tempVendorPool,
      selectedState,
      acceptVendors,
      isLoading,
      showModal,
      selectedVendorAlert,
      canUseCallout,
    } = this.state;
    console.log(canUseCallout);
    const csvDataDownload = vendorData.map((vendor) => {
      // Check if meals array exists and then check if all meals have a description
      const allMealsHaveDescription =
        vendor.meals?.every((meal) => meal.description?.trim() !== "") ?? false; // Default to false if 'meals' is undefined or empty

      // Check if meals array exists and then check if all meals have an image
      const allMealsHaveImage =
        vendor.meals?.every((meal) => meal.image) ?? false; // Default to false if 'meals' is undefined or empty

      return {
        "Vendor Outlet Name": vendor.title,
        "Vendor Location": vendor.address,
        "Vendor Email": vendor.email,
        "Vendor Contact Number": vendor.phone, // Assuming 'phone' is the correct field for contact number
        "Truck Dimensions": `${vendor.truck_height}x${vendor.truck_width}x${vendor.truck_depth}`,
        "Member Status": vendor.is_wtt_subscriber ? "Member" : "Not Member",
        "Number of 10 Amps": vendor.number_of_10_amp,
        "Number of 15 Amps": vendor.number_of_15_amp,
        "Number of 20 Amps": vendor.number_of_20_amp,
        "Number of 32 Amps": vendor.number_of_32_amp,
        "Water Access": vendor.water_access,
        "Electrical Certificate": vendor.electricial_certificate ? "Yes" : "No",
        "Fire Safety Certificate": vendor.fire_safety_certificate
          ? "Yes"
          : "No",
        "Food Registration Docs": vendor.food_refistration_docs ? "Yes" : "No",
        "Food Safety Certificate": vendor.food_safety_certificate
          ? "Yes"
          : "No",
        "Gas Certificate": vendor.gas_certificate ? "Yes" : "No",
        "Liability Certificate": vendor.liablity_certificate ? "Yes" : "No",
        "Working With Children Check": vendor.working_with_childrens_check
          ? "Yes"
          : "No",
        "Work Cover Insurance": vendor.work_cover_insurance ? "Yes" : "No",
        "Street Trader File": vendor.streat_trader_file ? "Yes" : "No",
        "Site Layout": vendor.site_layout ? "Yes" : "No",
        Image: vendor.image ? "Yes" : "No",
        Logo: vendor.logo ? "Yes" : "No",
        "Meal Description": allMealsHaveDescription ? "Yes" : "No",
        "Meal Images": allMealsHaveImage ? "Yes" : "No",
      };
    });
    const csvAcceptedDataDownload = acceptedVendorData.map((vendor) => {
      const allMealsHaveDescription =
        vendor.meals?.every((meal) => meal.description?.trim() !== "") ?? false; // Default to false if 'meals' is undefined or empty

      // Check if meals array exists and then check if all meals have an image
      const allMealsHaveImage =
        vendor.meals?.every((meal) => meal.image) ?? false; // Default to false if 'meals' is undefined or empty

      return {
        "Vendor Outlet Name": vendor.title,
        "Vendor Location": vendor.address,
        "Vendor Email": vendor.email,
        "Vendor Contact Number": vendor.phone, // Assuming 'phone' is the correct field for contact number
        "Truck Dimensions": `${vendor.truck_height}x${vendor.truck_width}x${vendor.truck_depth}`,
        "Member Status": vendor.is_wtt_subscriber ? "Member" : "Not Member",
        "Number of 10 Amps": vendor.number_of_10_amp,
        "Number of 15 Amps": vendor.number_of_15_amp,
        "Number of 20 Amps": vendor.number_of_20_amp,
        "Number of 32 Amps": vendor.number_of_32_amp,
        "Water Access": vendor.water_access,
        "Electrical Certificate": vendor.electricial_certificate ? "Yes" : "No",
        "Fire Safety Certificate": vendor.fire_safety_certificate
          ? "Yes"
          : "No",
        "Food Registration Docs": vendor.food_refistration_docs ? "Yes" : "No",
        "Food Safety Certificate": vendor.food_safety_certificate
          ? "Yes"
          : "No",
        "Gas Certificate": vendor.gas_certificate ? "Yes" : "No",
        "Liability Certificate": vendor.liablity_certificate ? "Yes" : "No",
        "Working With Children Check": vendor.working_with_childrens_check
          ? "Yes"
          : "No",
        "Work Cover Insurance": vendor.work_cover_insurance ? "Yes" : "No",
        "Street Trader File": vendor.streat_trader_file ? "Yes" : "No",
        "Site Layout": vendor.site_layout ? "Yes" : "No",
        Image: vendor.image ? "Yes" : "No",
        Logo: vendor.logo ? "Yes" : "No",
        "Meal Description": allMealsHaveDescription ? "Yes" : "No",
        "Meal Images": allMealsHaveImage ? "Yes" : "No",
      };
    });
    const jsonToXLSX = (data, filename) => {
      console.log("clicked", data);
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    const bg = {
      processing: "#fff5cc",
      approved: "#c1f0c1",
      closed: "#ffc2b3",
    };
    const color = {
      processing: "#ffcc00",
      approved: "#2db92d",
      closed: "#e62e00",
    };
    const tooltips = {
      Electrician: "Ensures You Send All Power Requirements & Electrical Certificates to Electrician",
      "Health Department": "Ensures You Send All Statement Of Trades, Food Saferyu & Council Registration Documents",
      "City Council": "Manages city governance"
    };

    return (
      <>
        <div>
          <AdminNavBar
            style={{ margin: "auto" }}
            isSuperAdminLoggedIn={isSuperAdminLoggedIn}
            isOrganiserLoggedIn={isOrganiserLoggedIn}
            logout={this.logout}
            vendorName={vendorName}
          ></AdminNavBar>
          <div style={{ position: "relative" }}>
            {this.state.loading ? (
              <div
                style={{
                  textAlign: "center",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 1000,
                  background: "white",
                }}
              >
                <img src={require("../../../assets/img/loading.gif")}></img>
              </div>
            ) : null}
            <EventDetail
              open={this.state.isOpen}
              handleClose={this.onClose}
              vendorDetail={this.state.selectedVendor}
              eoiID={this.state.selectEvent.id}
              confirmVendor={this.onComfirm}
              rejectVendor={this.onReject}
            />
            <this.NoticeComponent
              document={this.state.missingDoc}
              noticeOpens={this.state.noticeOpen}
              selectedVendor={this.state.selectedVendor}
            />
            <Container style={{ minHeight: "600px" }}>
              <Paper
                elevation={3}
                square
                style={{
                  margin: "auto",
                  paddingTop: 20,
                  width: "80%",
                }}
              >
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="create-events-basicInfo"
                  style={{ margin: "auto", padding: 10 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Please Select your eoi
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      placeholder="Please select your event"
                      // InputLabelProps={{ shrink: true }}
                      value={this.state.selectEvent.eoi_event_name}
                      style={{ height: 60 }}
                      onChange={(e) => {
                        this.setState({ loading: true });
                        Promise.allSettled([
                          this.getTargetedEvents(e.target.value),
                          this.getTargetedAcceptedEvents(e.target.value),
                          this.getTargetedRejectedEvents(e.target.value),
                        ])
                          .then((r) => {
                            this.setState({ loading: false });
                          })
                          .catch((e) => {
                            this.setState({ loading: false });
                          });
                      }}
                    >
                      {this.state.eventList?.map((event, key) => (
                        <MenuItem
                          style={{ padding: 0 }}
                          value={event.id}
                          index={key}
                        >
                          <div
                            style={{
                              width: "100%",
                              padding: "16px 24px",
                              borderBottom: "1px solid #ccc",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ margin: 0 }}>
                              {event.eoi_event_name} {"   "}
                            </p>
                            <p
                              style={{
                                fontSize: 13,
                                padding: 4,
                                margin: 0,
                                borderRadius: 4,
                                backgroundColor: bg[event.eoi_status],
                                color: color[event.eoi_status],
                                borderWidth: 1,
                                borderColor: color[event.eoi_status],
                              }}
                            >
                              {event.eoi_status}
                            </p>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Grid container spacing={3}>
                    <Grid item lg={12}>
                      <Paper elevation={3} style={{ height: "auto" }}>
                        {this.state.selectEvent.eoi_event_name ? (
                          <Row>
                            <Box
                              style={{
                                position: "relative",
                                left: "10%",
                                borderRadius: 30,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                style={{
                                  marginTop: 10,
                                  marginTop: 10,
                                  height: 250,
                                  width: 100,
                                }}
                                className="d-block w-100"
                                src={
                                  AWS_URL +
                                  "/" +
                                  this.state.selectEvent.eoi_cover
                                }
                                onClick={this.toggleDetails}
                              ></img>
                              <Modal
                                open={isDetailsVisible}
                                onClose={this.toggleDetails}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 400,
                                    bgcolor: "background.paper",
                                    boxShadow: 24,
                                    p: 4,
                                  }}
                                >
                                  <h2 id="modal-modal-title">Vendor Details</h2>
                                  <p id="modal-modal-description">
                                    Here you can add details about the vendor.
                                  </p>
                                </Box>
                              </Modal>
                              <Col lg={12}>
                                <Typography style={{ paddingTop: 20 }}>
                                  EOI Name:
                                  {this.state.selectEvent.eoi_event_name}
                                </Typography>

                                {/* <Typography style={{ paddingTop: 20 }}>
                                <a
                                  href={this.state.selectEvent.event_terms_and_conditions}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Terms and Conditions
                                </a>

                              </Typography> */}
                                <Typography style={{ paddingTop: 20 }}>
                                  Event Location:{" "}
                                  {
                                    this.state.selectEvent
                                      .event_organiser_address
                                  }
                                </Typography>
                                <Typography style={{ paddingTop: 20 }}>
                                  Event Start Date/Time:
                                  {" " +
                                    moment(
                                      this.state.selectEvent
                                        .event_start_date_time
                                    ).format("MMMM Do YYYY, h:mm a")}
                                </Typography>
                                <Typography style={{ paddingTop: 20 }}>
                                  Event End Date/Time :
                                  {" " +
                                    moment(
                                      this.state.selectEvent.event_end_date_time
                                    ).format("MMMM Do YYYY, h:mm a")}
                                </Typography>
                                <Typography style={{ paddingTop: 20 }}>
                                  Bump in Date/Time:
                                  {" " +
                                    moment(
                                      this.state.selectEvent.bump_in_date_time
                                    ).format("MMMM Do YYYY, h:mm a")}
                                </Typography>
                                <Typography style={{ paddingTop: 20 }}>
                                  Bump out Date/Time:
                                  {" " +
                                    moment(
                                      this.state.selectEvent.bump_out_date_time
                                    ).format("MMMM Do YYYY, h:mm a")}
                                </Typography>
                              </Col>
                            </Box>
                            <Col
                              lg={12}
                              style={{
                                paddingTop: "20px",
                                height: "auto",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                              }}
                            >
                              <hr />
                              <div
                                style={{
                                  display: "flex",
                                  gap: 16,
                                  flexWrap: "wrap",
                                  marginBottom: 16,
                                }}
                              >
                               <FormControl style={{ minWidth: "250px", flex: 1 }}>
  Select Departmental Share Access
  <Select
    id="demo-simple-select"
    placeholder="Please select Departmental Share Access"
    value={this.state.accessType}
    style={{ height: 60 }}
    onChange={(e) => {
      this.setState({ accessType: e.target.value });
    }}
  >
    {this.state.accessOptions?.map((opt, key) => (
      <MenuItem value={opt} key={key}>
        <Tooltip title={tooltips[opt]}>
          <span>{opt}</span>
        </Tooltip>
      </MenuItem>
    ))}
  </Select>
</FormControl>
                                {this.state.accessType ? (
                                  <TextField
                                    inputRef={this.emailRef}
                                    style={{ minWidth: "250px", flex: 1 }}
                                    // onChange={(e) => {
                                    //   this.setState({
                                    //     accessEmail: e.target.value,
                                    //   });
                                    // }}
                                    label="Enter Email"
                                    variant="outlined"
                                  />
                                  
                                ) : null}
                                {this.state.accessType ? (
                                  <TextField
                                    inputRef={this.fullNameRef}
                                    style={{ minWidth: "250px", flex: 1 }}
                                    // onChange={(e) => {
                                    //   this.setState({
                                    //     accessEmail: e.target.value,
                                    //   });
                                    // }}
                                    label="Enter Full Name"
                                    variant="outlined"
                                  />
                                  
                                ) : null}
                              </div>

                              <Button
                                style={{
                                  background: !this.state.accessType
                                    ? "#f2a7f1"
                                    : "#ef3e6d",
                                  borderRadius: "6px",
                                  color: "white",
                                  border: "1px solid #ef3e6d",
                                  borderColor: !this.state.accessType
                                    ? "#f2a7f1"
                                    : "#ef3e6d",
                                }}
                                variant="contained"
                                onClick={this.handleSubmitShareAccess}
                                disabled={!this.state.accessType}
                              >
                                Submit
                              </Button>
                              <hr />
                            </Col>
                            <Col lg={12}>
                              {this.state.confirmVendors?.length != 0 ? (
                                <>
                                  <Container style={{ display: "flex" }}>
                                    {this.state.confirmVendors.length !== 0 &&
                                      this.state.confirmVendors.map((item) => {
                                        console.log(item);
                                        return (
                                          <Card
                                            key={item.id}
                                            style={{ width: 200 }}
                                          >
                                            <CardActionArea>
                                              <CardHeader
                                                title={
                                                  <Typography>
                                                    {item.title}
                                                  </Typography>
                                                }
                                              />
                                              <CardMedia
                                                style={{
                                                  width: 100,
                                                  height: 100,
                                                }}
                                                image={item.logo}
                                                title="vendor_logo"
                                              />
                                            </CardActionArea>
                                            <Button
                                              style={{ marginTop: 10 }}
                                              color="secondary"
                                              onClick={() =>
                                                this.removeVendor(item)
                                              }
                                            >
                                              Cancel
                                            </Button>
                                          </Card>
                                        );
                                      })}
                                  </Container>

                                  <Button
                                    color="primary"
                                    style={{ margin: 10 }}
                                    onClick={() => {
                                      // this.props.dispatch(
                                      //   getComfirmedVendors(
                                      //     this.state.confirmVendors
                                      //   )
                                      // );
                                      saveToLocalStorage(
                                        "comfirmedVendors",
                                        JSON.stringify(
                                          this.state.confirmVendors
                                        )
                                      );
                                      // this.props.dispatch(
                                      //   getSelectedEoi(this.state.selectEvent)
                                      // );
                                      saveToLocalStorage(
                                        "selectedEOI",
                                        JSON.stringify(this.state.selectEvent)
                                      );
                                      this.setState({
                                        contractNoticeOpen: true,
                                      });
                                    }}
                                  >
                                    View Contract
                                  </Button>
                                  <this.ContractNoticeComponent
                                    openContractNotice={
                                      this.state.contractNoticeOpen
                                    }
                                    selectEvent={this.state.selectEvent}
                                  />
                                  {this.state.selectEvent
                                    .is_single_date_event === false ? (
                                    <Button onClick={this.toggleModal}>
                                      Accept
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        const url =
                                          config.devUrl + apiPaths.adminData;
                                        let myHeaders = new Headers();
                                        myHeaders.append(
                                          "Content-Type",
                                          "application/json"
                                        );
                                        let vendorId = [];

                                        this.state.confirmVendors.map(
                                          (item) => {
                                            vendorId.push(item.id);
                                          }
                                        );

                                        const raw = JSON.stringify({
                                          payload: {
                                            body: {
                                              query_type:
                                                "accepted_vendor_outlets",
                                              event_id: this.state.selectEvent
                                                .id,
                                              accepted_vendor_ids: vendorId,
                                            },
                                          },
                                        });

                                        let requestOptions = {
                                          method: "POST",
                                          headers: myHeaders,
                                          body: raw,
                                          redirect: "follow",
                                        };
                                        try {
                                          fetch(url, requestOptions)
                                            .then((response) => response.json())
                                            .then((response) => {
                                              if (response.success == true) {
                                                alert("Apply successed!");
                                                this.setState({
                                                  confirmVendors: [],
                                                });
                                                this.getTargetedEvents(
                                                  this.state.selectEvent.id
                                                );
                                                this.getTargetedAcceptedEvents(
                                                  this.state.selectEvent.id
                                                );
                                              } else {
                                                alert(
                                                  "The application is failed. Please try later"
                                                );
                                                this.setState({
                                                  confirmVendors: [],
                                                });
                                                this.getTargetedEvents(
                                                  this.state.selectEvent.id
                                                );
                                              }
                                            });
                                        } catch (error) {
                                          Sentry.captureException(error);
                                          console.error(error);
                                          alert(
                                            "The application is failed. Please try later"
                                          );
                                          this.setState({
                                            confirmVendors: [],
                                          });
                                        }
                                        vendorId.length = 0;
                                      }}
                                    >
                                      Accept
                                    </Button>
                                  )}

                                  {showModal && (
                                    <ModalDate
                                      startDate={moment(
                                        this.state.selectEvent
                                          .event_start_date_time
                                      ).format("DD MMM YYYY")}
                                      endDate={moment(
                                        this.state.selectEvent
                                          .event_end_date_time
                                      ).format("DD MMM YYYY")}
                                      onSave={this.saveDatesAndSendData}
                                    />
                                  )}
                                </>
                              ) : null}
                            </Col>

                            <Col lg={12} style={{ marginTop: 20 }}>
                              <>
                                <div className="center-button-report">
                                  {!isLoading ? (
                                    <CsvDownloadButton
                                      data={csvDataDownload}
                                      onClick={() =>
                                        jsonToXLSX(
                                          csvDataDownload,
                                          "applied_vendors"
                                        )
                                      }
                                      filename="applied_vendors.csv"
                                      className="button-report"
                                      style={{
                                        background: "#ef3e6d",
                                        borderRadius: "6px",
                                        border: "1px solid #ef3e6d",
                                        display: "inline-block",
                                        cursor: "pointer",
                                        color: "#ffffff",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        padding: "6px 24px",
                                        textDecoration: "none",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Download Applied Vendors
                                    </CsvDownloadButton>
                                  ) : (
                                    <button
                                      disabled
                                      style={{
                                        background: "#ccc", // Use a suitable color for disabled state
                                        borderRadius: "6px",
                                        border: "1px solid #ccc",
                                        display: "inline-block",
                                        cursor: "not-allowed",
                                        color: "#ffffff",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        padding: "6px 24px",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Download Applied Vendors
                                    </button>
                                  )}
                                </div>
                                <div className="container-accordion">
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      style={{
                                        backgroundColor:
                                          canUseCallout === "false"
                                            ? "#DAA520"
                                            : "#2959a5",
                                        color: "white",
                                      }}
                                    >
                                      <Typography>
                                        <strong>VENDOR POOL</strong>
                                        <WorkspacePremiumIcon
                                          style={{ color: "white" }}
                                        />
                                      </Typography>
                                    </AccordionSummary>
                                    {canUseCallout === "true" ? (
                                      <AccordionDetails>
                                        <Typography>
                                          <div className="center-button-report">
                                            <AlertBox
                                              selectedEvent={
                                                this.state.selectEvent
                                              }
                                            />
                                          </div>
                                          <InputGroup className="mb-3">
                                            <InputGroup.Text value={vendorPool}>
                                              <img
                                                style={{ width: 20 }}
                                                src={require("../../../assets/img/search.gif")}
                                              ></img>
                                            </InputGroup.Text>
                                            <FC
                                              placeholder="Search Vendors"
                                              aria-label="Search for vendors"
                                              onChange={(e) => {
                                                var searchString =
                                                  e.target.value;
                                                if (searchString == "") {
                                                  this.setState({
                                                    tempVendorPool: this.state
                                                      .vendorPool,
                                                  });
                                                } else {
                                                  var result = tempVendorPool.filter(
                                                    (obj) => {
                                                      if (
                                                        obj.title.search(
                                                          new RegExp(
                                                            searchString,
                                                            "i"
                                                          )
                                                        ) != -1
                                                      ) {
                                                        return obj;
                                                      }
                                                    }
                                                  );
                                                  this.setState({
                                                    tempVendorPool: result,
                                                  });
                                                }
                                              }}
                                            />
                                          </InputGroup>
                                          <div>
                                            <select
                                              value={selectedState}
                                              onChange={this.handleChange}
                                            >
                                              <option value="">
                                                All States
                                              </option>
                                              {states.map((state) => (
                                                <option
                                                  key={state}
                                                  value={state}
                                                >
                                                  {state}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          {tempVendorPool.length != 0 ? (
                                            <List
                                              style={{
                                                width: "100%",
                                                overflow: "auto",
                                                maxHeight: 300,
                                                position: "relative",
                                              }}
                                            >
                                              {tempVendorPool.map((value) => {
                                                const labelId = `checkbox-list-label-${value.user_ptr_id}`;

                                                return (
                                                  <>
                                                    <div
                                                      key={value.user_ptr_id}
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }} // This div will hold the checkbox and the list item inline
                                                    >
                                                      <ListItem
                                                        key={value.user_ptr_id}
                                                        role={undefined}
                                                        button
                                                        style={{
                                                          flex: 1,
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          justifyContent:
                                                            "space-around",
                                                        }}
                                                        onClick={() => {
                                                          console.log(value); // This will log the `value` to the console
                                                          this.setState({
                                                            selectedVendorAlert: value,
                                                          });
                                                        }}
                                                      >
                                                        <ListItemIcon
                                                          style={{
                                                            marginRight: 40,
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              value.logo &&
                                                              value.logo !== ""
                                                                ? `${AWS_URL}/${value.logo}`
                                                                : require("../../../assets/img/empty_logo.png")
                                                            }
                                                            alt="Vendor Logo"
                                                            style={{
                                                              height: 64,
                                                              width: 64,
                                                            }}
                                                            onClick={
                                                              this.toggleDetails
                                                            } // Attach the toggle method to onClick
                                                          />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                          id={labelId}
                                                        >
                                                          {value.title}
                                                        </ListItemText>
                                                        <Button
                                                          // onClick={this.handleSendAlert}
                                                          onClick={
                                                            this.toggleDialog
                                                          }
                                                          color="primary"
                                                        >
                                                          Send Alert
                                                        </Button>
                                                      </ListItem>
                                                    </div>
                                                  </>
                                                );
                                              })}
                                              <Dialog
                                                open={isDialogVisible}
                                                onClose={this.toggleDialog}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                              >
                                                <DialogTitle id="alert-dialog-title">
                                                  {"Send Alert"}
                                                </DialogTitle>
                                                <DialogContent>
                                                  <DialogContentText id="alert-dialog-description">
                                                    Are you sure you want to
                                                    send the alert?
                                                  </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                  <Button
                                                    onClick={this.toggleDialog}
                                                  >
                                                    Cancel
                                                  </Button>
                                                  <Button
                                                    onClick={
                                                      this.handleSendAlert
                                                    }
                                                    autoFocus
                                                  >
                                                    Confirm
                                                  </Button>
                                                </DialogActions>
                                              </Dialog>

                                              <Modal
                                                open={isDetailsVisible}
                                                onClose={this.toggleDetails}
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                              >
                                                <Box
                                                  style={{
                                                    backgroundColor: "#ffffff",

                                                    border: "2px solid #000",
                                                    boxShadow: 24,
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                      "translate(-50%, -50%)",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "550px",
                                                    height: "550px",
                                                    overflow: "auto",
                                                    padding: "20px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginTop: "30px", // Added top margin to ensure content isn't cut off
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        selectedVendorAlert?.logo &&
                                                        selectedVendorAlert.logo !==
                                                          ""
                                                          ? `${AWS_URL}/${selectedVendorAlert.logo}`
                                                          : require("../../../assets/img/empty_logo.png")
                                                      }
                                                      alt="Vendor Logo"
                                                      style={{
                                                        height: 64,
                                                        width: 64,
                                                        marginRight: "20px", // Ensure spacing between image and text
                                                      }}
                                                    />

                                                    <h1
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {selectedVendorAlert?.title ||
                                                        ""}
                                                    </h1>
                                                  </div>

                                                  <div>
                                                    <strong>Bio: </strong>
                                                    {selectedVendorAlert?.bio ||
                                                      "N/A"}
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: "grid",
                                                      gridTemplateColumns:
                                                        "auto auto",
                                                      gap: "10px 20px",
                                                      alignItems: "start",
                                                    }}
                                                  >
                                                    <div>
                                                      <strong>Address:</strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.address &&
                                                      selectedVendorAlert?.hometown
                                                        ? `${selectedVendorAlert.address}, ${selectedVendorAlert.hometown}`
                                                        : "N/A"}{" "}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        Truck Dimensions:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.truck_height &&
                                                      selectedVendorAlert?.truck_width &&
                                                      selectedVendorAlert?.truck_depth
                                                        ? `${selectedVendorAlert.truck_height} * ${selectedVendorAlert.truck_width} * ${selectedVendorAlert.truck_depth}`
                                                        : "N/A"}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        Truck Exit:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.truck_exit ||
                                                        "N/A"}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        10 Amp Devices:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.number_of_10_amp ||
                                                        "0"}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        15 Amp Devices:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.number_of_15_amp ||
                                                        "0"}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        20 Amp Devices:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.number_of_20_amp ||
                                                        "0"}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        30 Amp Devices:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.number_of_30_amp ||
                                                        "0"}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        Water Access:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.water_access ||
                                                        "N/A"}
                                                    </div>

                                                    <div>
                                                      <strong>
                                                        Fire Source:
                                                      </strong>
                                                    </div>
                                                    <div>
                                                      {selectedVendorAlert?.fire_source ||
                                                        "0"}
                                                    </div>
                                                  </div>

                                                  <Button
                                                    color="primary"
                                                    variant="contained"
                                                    style={{ margin: 10 }}
                                                    onClick={this.toggleDetails}
                                                  >
                                                    Close
                                                  </Button>
                                                </Box>
                                              </Modal>
                                            </List>
                                          ) : (
                                            <Col
                                              lg={12}
                                              style={{
                                                display: "-ms-inline-flexbox",
                                              }}
                                            >
                                              There are no vendors to alert
                                            </Col>
                                          )}
                                        </Typography>
                                      </AccordionDetails>
                                    ) : (
                                      <AccordionDetails>
                                        <div className="membership-promotion">
                                          <Typography
                                            component="h5"
                                            className="promotional-header"
                                          >
                                            <h5>
                                              Access this data and more as a WTT
                                              Subscriber
                                            </h5>
                                          </Typography>
                                          <ul className="benefits-list">
                                            <li>Access to all sales data</li>
                                            <li>
                                              Get insights for your next event
                                            </li>
                                          </ul>
                                          <Button
                                            style={{
                                              backgroundColor: "#2959a5", // Background color
                                              color: "white", // Text color for contrast
                                              padding: "10px 20px", // Optional: Adjust padding
                                              borderRadius: "4px", // Optional: Border radius
                                            }}
                                          >
                                            Add ons/ Subscription
                                          </Button>
                                        </div>

                                        <div className="blurred-box">
                                          <div className="blurred-text">
                                            <Typography>
                                              {tempVendorPool.length != 0 ? (
                                                <List
                                                  style={{
                                                    width: "100%",
                                                    overflow: "auto",
                                                    maxHeight: 300,
                                                    position: "relative",
                                                  }}
                                                >
                                                  {tempVendorPool.map(
                                                    (value) => {
                                                      const labelId = `checkbox-list-label-${value.user_ptr_id}`;

                                                      return (
                                                        <>
                                                          <div
                                                            key={
                                                              value.user_ptr_id
                                                            }
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }} // This div will hold the checkbox and the list item inline
                                                          >
                                                            <ListItem
                                                              key={
                                                                value.user_ptr_id
                                                              }
                                                              role={undefined}
                                                              button
                                                              style={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                                justifyContent:
                                                                  "space-around",
                                                              }}
                                                              onClick={() => {
                                                                console.log(
                                                                  value
                                                                ); // This will log the `value` to the console
                                                                this.setState({
                                                                  selectedVendorAlert: value,
                                                                });
                                                              }}
                                                            >
                                                              <ListItemIcon
                                                                style={{
                                                                  marginRight: 40,
                                                                }}
                                                              >
                                                                <img
                                                                  src={
                                                                    value.logo &&
                                                                    value.logo !==
                                                                      ""
                                                                      ? `${AWS_URL}/${value.logo}`
                                                                      : require("../../../assets/img/empty_logo.png")
                                                                  }
                                                                  alt="Vendor Logo"
                                                                  style={{
                                                                    height: 64,
                                                                    width: 64,
                                                                  }}
                                                                  onClick={
                                                                    this
                                                                      .toggleDetails
                                                                  } // Attach the toggle method to onClick
                                                                />
                                                              </ListItemIcon>
                                                              <ListItemText
                                                                id={labelId}
                                                              >
                                                                {value.title}
                                                              </ListItemText>
                                                              <Button
                                                                // onClick={this.handleSendAlert}
                                                                onClick={
                                                                  this
                                                                    .toggleDialog
                                                                }
                                                                color="primary"
                                                              >
                                                                Send Alert
                                                              </Button>
                                                            </ListItem>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                  <Dialog
                                                    open={isDialogVisible}
                                                    onClose={this.toggleDialog}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                  >
                                                    <DialogTitle id="alert-dialog-title">
                                                      {"Send Alert"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                      <DialogContentText id="alert-dialog-description">
                                                        Are you sure you want to
                                                        send the alert?
                                                      </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                      <Button
                                                        onClick={
                                                          this.toggleDialog
                                                        }
                                                      >
                                                        Cancel
                                                      </Button>
                                                      <Button
                                                        onClick={
                                                          this.handleSendAlert
                                                        }
                                                        autoFocus
                                                      >
                                                        Confirm
                                                      </Button>
                                                    </DialogActions>
                                                  </Dialog>

                                                  <Modal
                                                    open={isDetailsVisible}
                                                    onClose={this.toggleDetails}
                                                    aria-labelledby="transition-modal-title"
                                                    aria-describedby="transition-modal-description"
                                                  >
                                                    <Box
                                                      style={{
                                                        backgroundColor:
                                                          "#ffffff",

                                                        border:
                                                          "2px solid #000",
                                                        boxShadow: 24,
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform:
                                                          "translate(-50%, -50%)",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        width: "550px",
                                                        height: "550px",
                                                        overflow: "auto",
                                                        padding: "20px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          marginTop: "30px", // Added top margin to ensure content isn't cut off
                                                          paddingTop: "20px",
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            selectedVendorAlert?.logo &&
                                                            selectedVendorAlert.logo !==
                                                              ""
                                                              ? `${AWS_URL}/${selectedVendorAlert.logo}`
                                                              : require("../../../assets/img/empty_logo.png")
                                                          }
                                                          alt="Vendor Logo"
                                                          style={{
                                                            height: 64,
                                                            width: 64,
                                                            marginRight: "20px", // Ensure spacing between image and text
                                                          }}
                                                        />

                                                        <h1
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {selectedVendorAlert?.title ||
                                                            ""}
                                                        </h1>
                                                      </div>

                                                      <div>
                                                        <strong>Bio: </strong>
                                                        {selectedVendorAlert?.bio ||
                                                          "N/A"}
                                                      </div>

                                                      <div
                                                        style={{
                                                          display: "grid",
                                                          gridTemplateColumns:
                                                            "auto auto",
                                                          gap: "10px 20px",
                                                          alignItems: "start",
                                                        }}
                                                      >
                                                        <div>
                                                          <strong>
                                                            Address:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.address &&
                                                          selectedVendorAlert?.hometown
                                                            ? `${selectedVendorAlert.address}, ${selectedVendorAlert.hometown}`
                                                            : "N/A"}{" "}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            Truck Dimensions:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.truck_height &&
                                                          selectedVendorAlert?.truck_width &&
                                                          selectedVendorAlert?.truck_depth
                                                            ? `${selectedVendorAlert.truck_height} * ${selectedVendorAlert.truck_width} * ${selectedVendorAlert.truck_depth}`
                                                            : "N/A"}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            Truck Exit:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.truck_exit ||
                                                            "N/A"}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            10 Amp Devices:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.number_of_10_amp ||
                                                            "0"}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            15 Amp Devices:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.number_of_15_amp ||
                                                            "0"}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            20 Amp Devices:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.number_of_20_amp ||
                                                            "0"}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            30 Amp Devices:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.number_of_30_amp ||
                                                            "0"}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            Water Access:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.water_access ||
                                                            "N/A"}
                                                        </div>

                                                        <div>
                                                          <strong>
                                                            Fire Source:
                                                          </strong>
                                                        </div>
                                                        <div>
                                                          {selectedVendorAlert?.fire_source ||
                                                            "0"}
                                                        </div>
                                                      </div>

                                                      <Button
                                                        color="primary"
                                                        variant="contained"
                                                        style={{ margin: 10 }}
                                                        onClick={
                                                          this.toggleDetails
                                                        }
                                                      >
                                                        Close
                                                      </Button>
                                                    </Box>
                                                  </Modal>
                                                </List>
                                              ) : (
                                                <Col
                                                  lg={12}
                                                  style={{
                                                    display:
                                                      "-ms-inline-flexbox",
                                                  }}
                                                >
                                                  There are no vendors to alert
                                                </Col>
                                              )}
                                            </Typography>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    )}
                                  </Accordion>
                                </div>
                                <div className="container-accordion">
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        <strong>EOI APPLICATIONS</strong>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        {vendorData.length != 0 ? (
                                          <List
                                            style={{
                                              width: "100%",
                                              overflow: "auto",
                                              maxHeight: 300,
                                              position: "relative",
                                            }}
                                          >
                                            {vendorData.map((value) => {
                                              const labelId = `checkbox-list-label-${value.id}`;
                                              const hasMeals =
                                                value.meals &&
                                                value.meals.length > 0;
                                              const hasMealImageAndDescription =
                                                hasMeals &&
                                                value.meals.every(
                                                  (meal) =>
                                                    meal.description &&
                                                    meal.image
                                                );

                                              return (
                                                <>
                                                  <div
                                                    key={value.id}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title={
                                                        hasMealImageAndDescription
                                                          ? "Delete"
                                                          : "Meals image or description is missing so you cannot select this vendor, please alert the vendor"
                                                      }
                                                    >
                                                      <span>
                                                        <Checkbox
                                                          edge="start"
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              this.setState(
                                                                (
                                                                  prevState
                                                                ) => ({
                                                                  selectedVendorIds: [
                                                                    ...prevState.selectedVendorIds,
                                                                    value.id,
                                                                  ],
                                                                })
                                                              );
                                                            } else {
                                                              this.setState(
                                                                (
                                                                  prevState
                                                                ) => ({
                                                                  selectedVendorIds: prevState.selectedVendorIds.filter(
                                                                    (id) =>
                                                                      id !==
                                                                      value.id
                                                                  ),
                                                                })
                                                              );
                                                            }
                                                          }}
                                                          checked={this.state.selectedVendorIds.includes(
                                                            value.id
                                                          )}
                                                          tabIndex={-1}
                                                          disableRipple
                                                          disabled={
                                                            !hasMealImageAndDescription
                                                          } // Disable the checkbox if meals are missing image or description
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                    <ListItem
                                                      key={value.id}
                                                      role={undefined}
                                                      button
                                                      style={{
                                                        flex: 1,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent:
                                                          "space-around",
                                                      }}
                                                      onClick={() =>
                                                        this.setState({
                                                          isOpen: true,
                                                          selectedVendor: value,
                                                        })
                                                      }
                                                    >
                                                      <ListItemIcon
                                                        style={{
                                                          marginRight: 40,
                                                        }}
                                                      >
                                                        <img
                                                          src={value.logo}
                                                          alt="vendor_logo"
                                                          style={{
                                                            height: 64,
                                                            width: 64,
                                                          }}
                                                        />
                                                      </ListItemIcon>
                                                      <ListItemText
                                                        id={labelId}
                                                      >
                                                        {value.title}
                                                      </ListItemText>
                                                    </ListItem>
                                                  </div>
                                                </>
                                              );
                                            })}
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "10px",
                                                gap: "20px", // Add gap between buttons
                                              }}
                                            >
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                  this.state.selectedVendorIds
                                                    .length === 0
                                                } // Disable button if no vendor is selected
                                                onClick={this.acceptVendors}
                                                // onClick={() => {
                                                //   console.log(
                                                //     "Accept",
                                                //     this.state.selectedVendorIds
                                                //   ); // Handle accept action
                                                // }}
                                              >
                                                Accept
                                              </Button>
                                              {/* <Button
                                              variant="contained"
                                              color="secondary"
                                              disabled={
                                                this.state.selectedVendorIds
                                                  .length === 0
                                              } // Disable button if no vendor is selected
                                              onClick={() => {
                                                console.log(
                                                  "Reject",
                                                  this.state.selectedVendorIds
                                                ); // Handle reject action
                                              }}
                                            >
                                              Reject
                                            </Button> */}
                                            </div>
                                          </List>
                                        ) : (
                                          <Col
                                            lg={12}
                                            style={{
                                              display: "-ms-inline-flexbox",
                                            }}
                                          >
                                            There are no vendors applied yet
                                          </Col>
                                        )}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                                <div className="container-accordion">
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        <strong>ACCEPTED REQUESTS</strong>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        {acceptedVendorData.length != 0 ? (
                                          <List
                                            style={{
                                              width: "100%",
                                              overflow: "auto",
                                              maxHeight: 300,
                                              position: "relative",
                                            }}
                                          >
                                            <div className="center-button-report">
                                              {!isLoading ? (
                                                <CsvDownloadButton
                                                  data={csvAcceptedDataDownload}
                                                  onClick={() =>
                                                    jsonToXLSX(
                                                      csvAcceptedDataDownload,
                                                      "accepted_vendors"
                                                    )
                                                  }
                                                  filename="applied_vendors.csv"
                                                  className="button-report"
                                                  style={{
                                                    background: "#ef3e6d",
                                                    backgroundColor: "#ef3e6d",
                                                    borderRadius: "6px",
                                                    border: "1px solid #ef3e6d",
                                                    display: "inline-block",
                                                    cursor: "pointer",
                                                    color: "#ffffff",
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    padding: "6px 24px",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  Download Accepted Vendors
                                                </CsvDownloadButton>
                                              ) : (
                                                <button
                                                  disabled
                                                  style={{
                                                    background: "#ccc", // Use a suitable color for disabled state
                                                    borderRadius: "6px",
                                                    border: "1px solid #ccc",
                                                    display: "inline-block",
                                                    cursor: "not-allowed",
                                                    color: "#ffffff",
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    padding: "6px 24px",
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  Download Accepted Vendors
                                                </button>
                                              )}
                                            </div>
                                            {acceptedVendorData.map((value) => {
                                              const labelId = `checkbox-list-label-${value.id}`;

                                              return (
                                                <ListItem
                                                  key={value.id}
                                                  role={undefined}
                                                  button
                                                  style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "space-around",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      isOpen: true,
                                                      selectedVendor: value,
                                                    })
                                                  }
                                                >
                                                  <ListItemIcon
                                                    style={{
                                                      marginRight: 40,
                                                    }}
                                                  >
                                                    <img
                                                      src={value.logo}
                                                      alt="vendor_logo"
                                                      style={{
                                                        height: 64,
                                                        width: 64,
                                                      }}
                                                    />
                                                  </ListItemIcon>
                                                  <ListItemText id={labelId}>
                                                    {value.title}
                                                  </ListItemText>
                                                </ListItem>
                                              );
                                            })}
                                          </List>
                                        ) : (
                                          <Col
                                            lg={12}
                                            style={{
                                              display: "-ms-inline-flexbox",
                                            }}
                                          >
                                            There are no vendors applied yet
                                          </Col>
                                        )}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                                <div className="container-accordion">
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        <strong>REJECTED REQUESTS</strong>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        {rejectedVendorData.length != 0 ? (
                                          <List
                                            style={{
                                              width: "100%",
                                              overflow: "auto",
                                              maxHeight: 300,
                                              position: "relative",
                                            }}
                                          >
                                            {this.state.rejectedVendorData.map(
                                              (value) => {
                                                const labelId = `checkbox-list-label-${value.id}`;

                                                return (
                                                  <ListItem
                                                    key={value.id}
                                                    role={undefined}
                                                    button
                                                    style={{
                                                      flex: 1,
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent:
                                                        "space-around",
                                                    }}
                                                    onClick={() =>
                                                      this.setState({
                                                        isOpen: true,
                                                        selectedVendor: value,
                                                      })
                                                    }
                                                  >
                                                    <ListItemIcon
                                                      style={{
                                                        marginRight: 40,
                                                      }}
                                                    >
                                                      <img
                                                        src={value.logo}
                                                        alt="vendor_logo"
                                                        style={{
                                                          height: 64,
                                                          width: 64,
                                                        }}
                                                      />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId}>
                                                      {value.title}
                                                    </ListItemText>
                                                  </ListItem>
                                                );
                                              }
                                            )}
                                          </List>
                                        ) : (
                                          <Col
                                            lg={12}
                                            style={{
                                              display: "-ms-inline-flexbox",
                                            }}
                                          >
                                            There are no vendors applied yet
                                          </Col>
                                        )}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </>
                            </Col>
                          </Row>
                        ) : null}
                      </Paper>
                    </Grid>
                  </Grid>
                </Col>
              </Paper>
            </Container>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    eventDetail: state.organiser.newEventDetail,
    EOI: state.organiser,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(ViewPendingVendors, {
    fallback: <ErrorFallbackComponent />,
  })
);
 