import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { List, X } from "react-bootstrap-icons";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  deleteFromLocalStorage,
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../../store";

export default function VendorNavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOperatorLoggedIn] = useState(() =>
    getFromLocalStorage("isOperatorLoggedIn")
  );
  const [isTruckManager] = useState(() =>
    getFromLocalStorage("isTruckManager")
  );
  const [isOperatorAccountant] = useState(() =>
    getFromLocalStorage("isOperatorAccountant")
  );
  const [isOperatorOperations] = useState(() =>
    getFromLocalStorage("isOperatorOperations")
  );
  const [isOperatorEventCoordinator] = useState(() =>
    getFromLocalStorage("isOperatorEventCoordinator")
  );
  const [isOperatorStockAdmin] = useState(() =>
    getFromLocalStorage("isOperatorStockAdmin")
  );

  const logOut = () => {
    saveToLocalStorage("isOperatorLoggedIn", false);
    saveToLocalStorage("isTruckManager", false);
    saveToLocalStorage("isOperatorAccountant", false);
    saveToLocalStorage("isOperatorOperations", false);
    saveToLocalStorage("isOperatorEventCoordinator", false);
    saveToLocalStorage("isOperatorStockAdmin", false);
    deleteFromLocalStorage("Operator");
    window.location.replace("/");
  };

  const style = {
    marginBottom: "20px",
    backgroundColor: "rgb(40, 89, 165)",
  };

  return (
    <Navbar style={style} variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={require("../../../assets/img/welcome-logo.png").default}
            height="40"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <X /> : <List />}
        </Navbar.Toggle>
        <Navbar.Collapse className={isOpen ? "show" : ""}>
          <Nav className="me-auto">
            {isOperatorLoggedIn === "true" ? (
              <Nav.Link href="/apply-events/home">Apply</Nav.Link>
            ) : null}
            {isOperatorLoggedIn === "true" ? (
              <Nav.Link href="/apply-events/applied-eois">
                Applied EOIs
              </Nav.Link>
            ) : null}
            {isOperatorLoggedIn === "true" || isTruckManager === "true" ? (
              <Nav.Link href="/operations-logs">Accident Logs</Nav.Link>
            ) : null}
            {isOperatorLoggedIn === "true" ? (
              <NavDropdown title="Operations" id="basic-nav-dropdown">
                <NavDropdown.Item href="/operator-sots">
                  Statement Of Trades
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/operator-public-liability-with-interested-party">
                    Public Liability With Interest To Party
                  </NavDropdown.Item> */}
                <NavDropdown.Item href="/operator-pos-details">
                  Pos Details
                </NavDropdown.Item>
                <NavDropdown.Item href="/operator-run-sheets">
                  Event Runsheets
                </NavDropdown.Item>
                <NavDropdown.Item href="/operator-contracts">
                  Pending Contracts
                </NavDropdown.Item>
                <NavDropdown.Item href="/operator-subaccount">
                  Operator Subaccount
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
            {isOperatorLoggedIn === "true" ? (
              <NavDropdown title="My Outlets" id="basic-nav-dropdown">
                <NavDropdown.Item href="/apply-events/events-menu">
                  Events Menu
                </NavDropdown.Item>
                <NavDropdown.Item href="/apply-events/catering-menu">
                  Catering Menu
                </NavDropdown.Item>
                <NavDropdown.Item href="/apply-events/documents">
                  Documents
                </NavDropdown.Item>
                {isOperatorLoggedIn === "true" ? (
                  <NavDropdown.Item href="/operator-electrical">
                    Electrical Equipment List
                  </NavDropdown.Item>
                ) : null}
              </NavDropdown>
            ) : null}

            {isOperatorLoggedIn === "true" || isTruckManager === "true" ? (
              <NavDropdown title="Inventory" id="basic-nav-dropdown">
                {isOperatorLoggedIn === "true" ? (
                  <>
                    <NavDropdown.Item href="/operator-stock-inventory">
                      Operator Stock Inventory
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/operator-store-room">
                      Event Store Room
                    </NavDropdown.Item>
                  </>
                ) : null}
                <NavDropdown.Item href="/truck-stock-inventory">
                  Truck Stock Inventory
                </NavDropdown.Item>
                <NavDropdown.Item href="/closing-stock">
                  Closing Stock
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
            {isOperatorLoggedIn === "true" || isTruckManager === "true" ? (
              <NavDropdown title="Pre Event" id="basic-nav-dropdown">
                <NavDropdown.Item href="/operator-pre-event">
                  Pre Event Checklist
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
            {isOperatorLoggedIn === "true" || isTruckManager === "true" ? (
              <NavDropdown title="Post Event" id="basic-nav-dropdown">
                {isOperatorLoggedIn === "true" || isTruckManager === "true" ? (
                  <NavDropdown.Item href="/operator-post-event">
                    Post Event Checklist
                  </NavDropdown.Item>
                ) : null}
                {isOperatorLoggedIn === "true" ? (
                  <NavDropdown.Item href="/operator-sales-dashboard">
                    Sales By Dates
                  </NavDropdown.Item>
                ) : null}
                {isOperatorLoggedIn === "true" ? (
                  <NavDropdown.Item href="/operator-event-sales">
                    Sales By Event
                  </NavDropdown.Item>
                ) : null}
                {isOperatorLoggedIn === "true" ? (
                  <NavDropdown.Item href="/operator-orders-overview">
                    Sales By Each Transaction
                  </NavDropdown.Item>
                ) : null}
                {isOperatorLoggedIn === "true" ? (
                  <NavDropdown.Item href="/operator-event-expenses">
                    Event Expenses
                  </NavDropdown.Item>
                ) : null}

                {isOperatorLoggedIn === "true" ? (
                  <NavDropdown.Item href="/operator-post-event-report">
                    Post Event Report
                  </NavDropdown.Item>
                ) : null}
                {isOperatorLoggedIn === "true" ? (
                  <NavDropdown.Item href="/operator-payouts">
                    Payout History
                  </NavDropdown.Item>
                ) : null}
                {/* <NavDropdown.Item href="/operator-post-event-feedback">
                    Post Event Feedback Questions
                  </NavDropdown.Item> */}
              </NavDropdown>
            ) : null}
            {isOperatorLoggedIn === "true" || isTruckManager === "true" ? (
              <>
                <NavDropdown title="Event Purchases" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/stock">
                    Event Stock Purchase
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/request-stock-quote">
                    Request Stock Quote
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/utility-purchases">
                    Event Utility Purchase
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/apply-events/faqs">Help</Nav.Link>
              </>
            ) : null}

            {isOperatorLoggedIn === "true" ? (
              <>
                <NavDropdown title="My Account" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/vendor-profile/profile">
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/vendor-profile/payment">
                    Payments
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/vendor-profile/membership">
                    Membership
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/pending-subscriptions">
                    Sign Member Contract
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : null}


            {isTruckManager === "true" && (
              <>
                <NavDropdown title="Truck Manager" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/operator-run-sheets">
                    Event Runsheets
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}

            {isOperatorOperations === "true" && (
              <>
                <NavDropdown title="Operations" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/operator-run-sheets">
                    Event Runsheets
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-sots">
                    Statement Of Trades
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-post-event">
                    Post Event Checklist
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-pre-event">
                    Pre Event Checklist
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}

            {isOperatorAccountant === "true" && (
              <>
                <NavDropdown title="Post Event" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/operator-sales-dashboard">
                    Sales By Dates
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-event-sales">
                    Sales By Event
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-orders-overview">
                    Sales By Each Transaction
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-event-expenses">
                    Event Expenses
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-post-event-report">
                    Post Event Report
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-payouts">
                    Payout History
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href="/operator-post-event-feedback">
                    Post Event Feedback Questions
                  </NavDropdown.Item> */}
                  <NavDropdown.Item href="/vendor-profile/payment">
                    Payments
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}

            {isOperatorEventCoordinator === "true" && (
              <>
                <Nav.Link href="/apply-events/home">Apply</Nav.Link>
                <Nav.Link href="/apply-events/applied-eois">
                  Applied EOIs
                </Nav.Link>
                <NavDropdown title="My Outlets" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/apply-events/events-menu">
                    Events Menu
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/apply-events/catering-menu">
                    Catering Menu
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/apply-events/documents">
                    Documents
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/operator-electrical">
                    Electrical Equipment List
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link href="/operator-sots">Statement Of Trades</Nav.Link>
              </>
            )}

            {isOperatorStockAdmin === "true" && (
              <>
                <NavDropdown title="Event Purchases" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/stock">
                    Event Stock Purchase
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/request-stock-quote">
                    Request Stock Quote
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/utility-purchases">
                    Event Utility Purchase
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            <Nav.Link onClick={logOut}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
