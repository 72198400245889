import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { apiPaths } from "../../../services/apiPath";
import config from "../../../services/apiConfig";
import { getFromLocalStorage } from "../../../store";
import "./style.css";

const ClosingStockFormModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [closingStockData, setClosingStockData] = useState([]);

  const [eventId, setEventId] = useState("");
  const [vendorId, setVendorId] = useState("");

  const handleClose = () => {
    setFetchedEvents([]);
    setConfirmDialog(false);
    setEventId("");
    setVendorId("");
    setClosingStockData([]);
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);

  const fetchVendorIds = () => {
    setIsLoading(true);
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success === true) {
          setVendors(result.stalls ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        Sentry.captureException(error);
      });
  };
  const getAllEvents = (vendorId) => {
    setIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_upcoming_events_for_operators",
          vendor_id: Number(vendorId),
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorData, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success === true) {
          setFetchedEvents(dataResponse.data ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const getClosingStockData = (vendor_id, event_id) => {
    setIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_event_based_stock_order",
          vendor: Number(vendor_id),
          event_id: Number(event_id),
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorStockData, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success === true) {
          console.log("first");
          setClosingStockData(
            dataResponse.data
              ? dataResponse.data.map((item) => ({
                  ...item,
                  count: 0,
                  waste: 0,
                  opening: 0,
                  is_closing_transfered: false,

                  is_transfered_to_truck: false,
                  vendor_id_transfered_to: null,
                }))
              : []
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  useEffect(() => {
    if (showModal) fetchVendorIds();
    setVendorId(() => props.vendorId ?? "");
    if (props.vendorId) getAllEvents(props.vendorId);
    setEventId(() => props.eventId ?? "");
    if (props.eventId) getClosingStockData(props.vendorId, props.eventId);
  }, [showModal]);

  const clearState = () => {
    setEventId("");
    setConfirmDialog(false);
    setVendorId("");
    setClosingStockData([]);
    setFetchedEvents([]);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    const items = closingStockData.map((item) => {
      return {
        stocklist_id: item.id,
        count: item.count ?? 0,
        waste: item.waste ?? 0,
        is_closing_transfered: item.is_closing_transfered,
        additional_details: {
          is_transfered_to_truck: item.is_transfered_to_truck,
          vendor_id_transfered_to:
            item.is_transfered_to_truck && item.vendor_id_transfered_to
              ? Number(item.vendor_id_transfered_to)
              : null,
        },
      };
    });

    var url = config.BASE_URI + apiPaths.operatorStockData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "submit_event_stock_count",
          event_id: Number(eventId),
          vendor: Number(vendorId),
          vendor_id: Number(vendorId),
          data: items,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          clearState();
          alert("Closing Stock has been submitted successfully.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert("Something went wrong. Please try again.");
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col></Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button variant="primary" onClick={handleShow}>
            Add Closing Stock
          </Button>
        </Col>
      </Row>
      <Modal
        dialogClassName="closing-modal"
        scrollable
        show={showModal}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Closing Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: "relative" }}>
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 1)", // Optional: Add a semi-transparent background
                zIndex: 9999, // Ensure it covers everything
              }}
            >
              <img
                src={require("../../../assets/img/loading.gif")}
                alt="Loading"
              />
            </div>
          ) : null}
          <form className="closing-form-container">
            <div className="closing-form-item">
              <label>Select Vendor:</label>
              <select
                style={{ flex: 1 }}
                onChange={(e) => {
                  const selectedVendorId = e.target.value;
                  setVendorId(selectedVendorId);
                  if (selectedVendorId) getAllEvents(Number(selectedVendorId));
                }}
                value={vendorId || ""}
              >
                <option value="">Select Vendor</option>
                {vendors.map((vendor, index) => (
                  <option key={index} value={vendor?.user_ptr_id}>
                    {vendor?.title}
                  </option>
                ))}
              </select>
            </div>

            {vendorId && fetchedEvents && fetchedEvents.length ? (
              <div className="closing-form-item">
                <label>Select Event:</label>
                <select
                  style={{ flex: 1 }}
                  onChange={(e) => {
                    setEventId(e.target.value);
                    if (e.target.value)
                      getClosingStockData(vendorId, e.target.value);
                  }}
                  value={eventId || ""}
                >
                  <option value="">Select Event</option>
                  {fetchedEvents.map((event, index) => (
                    <option key={event?.id} value={event?.id}>
                      {event?.name || ""}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            {closingStockData && closingStockData.length ? (
              <div>
                <Row className="table-header-row">
                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Stock ID
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Stock Name
                  </Col>
                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Quantity Type
                  </Col>

                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Opening Qty
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Closing Qty
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Wasted Qty
                  </Col>
                  <Col xs={12} md={2} lg={2}>
                    Item transferred to Store Room?
                    <input
                      style={{ marginLeft: 4 }}
                      onChange={(e) => {
                        if (e.target.checked === true)
                          setClosingStockData((st) => {
                            return st.map((item) => ({
                              ...item,
                              is_closing_transfered: true,
                            }));
                          });
                        else {
                          setClosingStockData((st) => {
                            return st.map((item) => ({
                              ...item,
                              is_closing_transfered: false,
                            }));
                          });
                        }
                      }}
                      type="checkbox"
                      id="all"
                      className="closing-check"
                      name="all"
                    />
                  </Col>
                  <Col xs={12} md={2} lg={2}>
                    Transfer to Truck?
                  </Col>
                </Row>
                {closingStockData.map((item, index) => (
                  <Row
                    key={item.id}
                    className="table-row"
                    style={{
                      backgroundColor:
                        item.count + item.waste > item.qty ? "#ffc2b3" : "",
                    }}
                  >
                    <Col xs={12} md={1} lg={1} className="table-col">
                      <b className="mobile-only">Stock ID:</b> {item.id}
                    </Col>

                    <Col xs={12} md={1} lg={1} className="table-col">
                      <b className="mobile-only">Item Name:</b> {item.name}
                    </Col>
                    <Col xs={12} md={1} lg={1} className="table-col">
                      <b className="mobile-only">Item Qty Type:</b>{" "}
                      {item.qty_type__qty_type}
                    </Col>

                    <Col xs={12} md={1} lg={1} className="table-col">
                      <b className="mobile-only">Opening Qty:</b> {item.qty}
                    </Col>

                    <Col xs={12} md={2} lg={2} className="table-col">
                      <b className="mobile-only">Closing Qty:</b>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ padding: 4, borderRadius: 0 }}
                          variant="primary"
                          aria-label="add"
                          size="small"
                          onClick={() => {
                            setClosingStockData((st) => {
                              const tempArr = [...st];
                              if (tempArr[index].count < tempArr[index].qty)
                                tempArr[index].count += 1;
                              return [...tempArr];
                            });
                          }}
                        >
                          <AddIcon fontSize="inherit" />
                        </Button>

                        <input
                          style={{
                            padding: 4,
                            textAlign: "center",
                            border:
                              item.count + item.waste > item.qty
                                ? "1px red solid"
                                : "",
                          }}
                          value={item.count}
                          onChange={(e) => {
                            setClosingStockData((st) => {
                              const tempArr = [...st];
                              tempArr[index].count = Number(e.target.value);
                              return [...tempArr];
                            });
                          }}
                        />

                        <Button
                          style={{ padding: 4, borderRadius: 0 }}
                          variant="primary"
                          aria-label="remove"
                          size="small"
                          onClick={() => {
                            setClosingStockData((st) => {
                              const tempArr = [...st];
                              if (tempArr[index].count > 0)
                                tempArr[index].count -= 1;
                              return [...tempArr];
                            });
                          }}
                        >
                          <RemoveIcon fontSize="inherit" />
                        </Button>
                      </div>
                      <p style={{ fontSize: 13, color: "red" }}>
                        {item.count + item.waste > item.qty
                          ? "The total of Closng Qty. and Wasted Qty. should not exceed Opening Qty."
                          : ""}
                      </p>
                    </Col>
                    <Col xs={12} md={2} lg={2} className="table-col">
                      <b className="mobile-only">Wasted Qty:</b>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ padding: 4, borderRadius: 0 }}
                          variant="primary"
                          aria-label="add"
                          size="small"
                          onClick={() => {
                            setClosingStockData((st) => {
                              const tempArr = [...st];
                              if (tempArr[index].waste < tempArr[index].qty)
                                tempArr[index].waste += 1;
                              return [...tempArr];
                            });
                          }}
                        >
                          <AddIcon fontSize="inherit" />
                        </Button>

                        <input
                          style={{
                            padding: 4,
                            textAlign: "center",
                            border:
                              item.count + item.waste > item.qty
                                ? "1px red solid"
                                : "",
                          }}
                          value={item.waste}
                          onChange={(e) => {
                            setClosingStockData((st) => {
                              const tempArr = [...st];
                              tempArr[index].waste = Number(e.target.value);
                              return [...tempArr];
                            });
                          }}
                        />

                        <Button
                          style={{ padding: 4, borderRadius: 0 }}
                          variant="primary"
                          aria-label="remove"
                          size="small"
                          onClick={() => {
                            setClosingStockData((st) => {
                              const tempArr = [...st];
                              if (tempArr[index].waste > 0)
                                tempArr[index].waste -= 1;
                              return [...tempArr];
                            });
                          }}
                        >
                          <RemoveIcon fontSize="inherit" />
                        </Button>
                      </div>
                      <p style={{ fontSize: 13, color: "red" }}>
                        {item.count + item.waste > item.qty
                          ? "The total of Closng Qty. and Wasted Qty. should not exceed Opening Qty."
                          : ""}
                      </p>
                    </Col>
                    <Col xs={12} md={2} lg={2}>
                      <b className="mobile-only">
                        Item transferred to Store Room?:
                      </b>
                      <input
                        style={{ marginLeft: 4 }}
                        onChange={(e) => {
                          if (e.target.checked === true)
                            setClosingStockData((st) => {
                              let temp = [...st];
                              let itemIdx = st.findIndex(
                                (it) => it.id === item.id
                              );
                              if (itemIdx >= 0) {
                                temp[itemIdx] = {
                                  ...temp[itemIdx],
                                  is_closing_transfered: true,
                                };
                              }

                              return [...temp];
                            });
                          else {
                            setClosingStockData((st) => {
                              let temp = [...st];
                              let itemIdx = st.findIndex(
                                (it) => it.id === item.id
                              );
                              if (itemIdx >= 0) {
                                temp[itemIdx] = {
                                  ...temp[itemIdx],
                                  is_closing_transfered: false,
                                };
                              }

                              return [...temp];
                            });
                          }
                        }}
                        checked={item.is_closing_transfered}
                        type="checkbox"
                        id={item.id}
                        className="closing-check"
                        name={item.id}
                      />
                    </Col>
                    <Col xs={12} md={2} lg={2}>
                      <b className="mobile-only">Transfer to Truck?:</b>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                        }}
                      >
                        <input
                          style={{ marginLeft: 4 }}
                          onChange={(e) => {
                            if (e.target.checked === true)
                              setClosingStockData((st) => {
                                let temp = [...st];
                                let itemIdx = st.findIndex(
                                  (it) => it.id === item.id
                                );
                                if (itemIdx >= 0) {
                                  temp[itemIdx] = {
                                    ...temp[itemIdx],

                                    is_transfered_to_truck: true,
                                  };
                                }

                                return [...temp];
                              });
                            else {
                              setClosingStockData((st) => {
                                let temp = [...st];
                                let itemIdx = st.findIndex(
                                  (it) => it.id === item.id
                                );
                                if (itemIdx >= 0) {
                                  temp[itemIdx] = {
                                    ...temp[itemIdx],

                                    is_transfered_to_truck: false,
                                  };
                                }

                                return [...temp];
                              });
                            }
                          }}
                          checked={item.is_transfered_to_truck}
                          type="checkbox"
                          id={item.id}
                          className="closing-check"
                          name={item.id}
                        />
                        {item.is_transfered_to_truck ? (
                          <div style={{ flex: 1, width: "100%" }}>
                            <label>Select Truck:</label>
                            <select
                              style={{
                                flex: 1,
                                width: "100%",
                                fontSize: 14,
                                padding: 4,
                                margin: 0,
                              }}
                              onChange={(e) => {
                                setClosingStockData((st) => {
                                  const tempArr = [...st];
                                  tempArr[index].vendor_id_transfered_to =
                                    e.target.value;
                                  return [...tempArr];
                                });
                              }}
                              value={item.vendor_id_transfered_to || ""}
                            >
                              <option value="">Select Vendor</option>
                              {vendors.map((vendor, index) => (
                                <option key={index} value={vendor?.user_ptr_id}>
                                  {vendor?.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            ) : null}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={
              closingStockData &&
              closingStockData.length &&
              closingStockData.some(
                (item) => item.count + item.waste > item.qty
              )
            }
            onClick={() => {
              setConfirmDialog(true);
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={confirmDialog}
        onHide={() => {
          setConfirmDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submit Closing Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wan tto submit the changes?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setConfirmDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClosingStockFormModal;
